<template>
  <v-row>
    <!-- kitchen sink -->
    <v-col cols="12">
      <v-card>
        <v-card-title>
          {{ `${$t('menu.booking')} / ${$t('menu.reservas')}` }}
        </v-card-title>
        <v-card-text><ReservasList></ReservasList></v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

import ReservasList from './tablas/ReservasList.vue'

export default {
  components: {
    ReservasList,
  },
  data() {
    return {}
  },
}
</script>
