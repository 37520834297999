<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div v-if="!isLoading">
    <FiltroReservas
      v-if="pos === 0"
      :user="user"
      :tipos_product="tipos_product"
      :afiliados="afiliadosList"
      :seller="sellerList"
      :codes="codes"
      :states="states"
    />
    <FiltroCotizations
      v-else-if="pos === 1"
      :user="user"
      :tipos_product="tipos_product"
      :afiliados="afiliadosList"
      :seller="sellerList"
      :codes="codesCotizations"
      :states="states"
    />
    <!--<v-card-text>
      <v-row>
        <v-spacer></v-spacer>
        <v-col
          cols="12"
          md="2"
        >
          <v-text-field
            v-model="itemsPerPage"
            :label="$t('lbl.itemsPerPage')"
            type="number"
            min="5"
            hide-details
            dense
            outlined
            @input="changeCantItems()"
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="search"
            :append-icon="icons.mdiMagnify"
            :label="$t('btn.search')"
            single-line
            hide-details
            dense
            outlined
            @input="getReservas()"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>-->

    <!--<v-form class="multi-col-validation px-5 py-5">
      <v-row>
        <v-col
          cols="12"
          md="3"
        >
          <strong>{{ pos === 0 ? $t('lbl.reserveFrom') : $t('lbl.cotizationFrom') }}</strong>
          <v-menu
            v-model="menuFromReserve"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedFromReserveDateFormatted"
                :label="pos === 0 ? $t('lbl.reserveHome') : $t('lbl.reserveHome')"
                persistent-hint
                class="pt-2"
                readonly
                v-bind="attrs"
                outlined
                dense
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="search.dateReserveFrom"
              no-title
              locale="es"
              :show-current="false"
              @input="menuFromReserve = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <strong>{{ pos === 0 ? $t('lbl.reserveTo') : $t('lbl.cotizationTo') }}</strong>
          <v-menu
            v-model="menuToReserve"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedToReserveDateFormatted"
                :label="pos === 0 ? $t('lbl.reserveEnd') : $t('lbl.reserveEnd')"
                persistent-hint
                class="pt-2"
                readonly
                v-bind="attrs"
                outlined
                dense
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="search.dateReserveTo"
              no-title
              :max="new Date(Date.now()).toISOString().substr(0, 10)"
              locale="es"
              :show-current="false"
              @input="menuToReserve = false"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col
          cols="12"
          md="3"
        >
          <strong>{{ $t('lbl.serviceFrom') }}</strong>
          <v-menu
            v-model="menuFromService"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedFromServiceDateFormatted"
                :label="$t('lbl.homeService')"
                persistent-hint
                class="pt-2"
                readonly
                v-bind="attrs"
                outlined
                dense
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="search.dateServiceFrom"
              no-title
              locale="es"
              :show-current="false"
              @input="menuFromService = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <strong>{{ $t('lbl.serviceTo') }}</strong>
          <v-menu
            v-model="menuToService"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedToServiceDateFormatted"
                :label="$t('lbl.endService')"
                persistent-hint
                class="pt-2"
                readonly
                v-bind="attrs"
                outlined
                dense
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="search.dateServiceTo"
              no-title
              locale="es"
              :show-current="false"
              @input="menuToService = false"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col
          cols="12"
          md="3"
        >
          <strong>{{ $t('lbl.typeProduct') }}</strong>
          <v-select
            v-model="search.tipo_product"
            :items="tipos_product"
            :label="$t('lbl.product')"
            outlined
            dense
            hide-details
            item-text="name"
            item-value="entity"
            class="pt-2"
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <strong>{{ $t('lbl.nameViajero') }}</strong>
          <v-text-field
            v-model="search.fullName"
            :label="$t('lbl.client')"
            hide-details
            dense
            outlined
            class="pt-2"
          ></v-text-field>
        </v-col>
        <v-col
          v-if="user.nivel <= 0"
          cols="12"
          md="3"
        >
          <strong>{{ $t('lbl.afiliate') }}</strong>
          <v-autocomplete
            v-model="search.afiliate"
            :items="afiliados"
            :search-input.sync="afiliadosSearch"
            hide-details
            hide-selected
            :label="$t('lbl.afiliateAgency')"
            outlined
            dense
            item-text="name"
            item-value="id"
            class="pt-2"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.afiliateAgency') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <strong>{{ $t('lbl.vendedor') }}</strong>
          <v-autocomplete
            v-model="search.seller"
            :items="seller"
            :search-input.sync="sellerSearch"
            hide-details
            hide-selected
            :label="$t('lbl.vendedor')"
            outlined
            dense
            item-text="name"
            item-value="id"
            class="pt-2"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.vendedor') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>

        <v-col
          cols="12"
          md="3"
        >
          <strong>{{ pos === 0 ? $t('lbl.codeReserva') : $t('lbl.codeCotization') }}</strong>
          <v-autocomplete
            v-model="search.codeReserva"
            :items="codesReserva"
            :search-input.sync="searchCodeReserva"
            hide-details
            hide-selected
            :label="$t('lbl.code')"
            outlined
            dense
            class="pt-2"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.code') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <strong>{{ pos === 0 ? $t('lbl.stateReserva') : $t('lbl.stateCotization') }}</strong>
          <v-select
            v-model="search.state"
            :items="states"
            :label="$t('lbl.state')"
            outlined
            dense
            hide-details
            item-text="name"
            item-value="id"
            class="pt-2"
          ></v-select>
        </v-col>
      </v-row>
    </v-form>
    <v-divider></v-divider>
    <v-form class="multi-col-validation px-5 py-5">
      <v-row>
        <v-col
          cols="12"
          md="1"
        >
          <v-btn
            v-if="
              search.tipo_product ||
                search.fullName ||
                search.codeReserva ||
                search.state ||
                search.dateReserveFrom ||
                search.dateReserveTo ||
                search.dateServiceFrom ||
                search.dateServiceTo ||
                search.afiliate ||
                search.seller
            "
            class="mx-2"
            fab
            outlined
            small
            color="error"
            @click="clearSearch()"
          >
            <v-icon small>
              {{ icons.mdiEraserVariant }}
            </v-icon>
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="9"
          class="text-center"
        >
          <v-btn
            :loading="loading"
            color="primary"
            @click="
              loading = true
              getReservas()
              getCotizations()
            "
          >
            <v-icon
              right
              dark
            >
              {{ icons.mdiMagnify }}
            </v-icon>
            <span class="pl-5">{{ $t('btn.search') }}</span>
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="2"
          class="text-lowercase"
        >
          <span v-if="pos === 0"> {{ totalF }} {{ $t('lbl.de') }} {{ totalItems }} {{ $t('menu.reservas') }} </span>
          <span v-if="pos === 1">
            {{ totalFCotization }} {{ $t('lbl.de') }} {{ totalItemsCotization }} {{ $t('menu.cotizations') }}
          </span>
        </v-col>
      </v-row>
    </v-form>-->

    <v-row>
      <v-spacer></v-spacer>
      <v-col
        cols="12"
        md="2"
        class="text-lowercase text-right"
      >
        <span v-if="pos === 0"> {{ reservasPaginate.length }} {{ $t('lbl.de') }} {{ reservasAll.length }} {{ $t('menu.reservas') }} </span>
        <span v-if="pos === 1">
          {{ cotizationsPaginate.length }} {{ $t('lbl.de') }} {{ cotizationsAll.length }} {{ $t('menu.cotizations') }}
        </span>
      </v-col>
    </v-row>
    <v-divider></v-divider>

    <v-tabs v-model="pos">
      <v-tab>
        {{ $t('menu.reservas') }}
      </v-tab>
      <v-tab>
        {{ $t('menu.cotizations') }}
      </v-tab>
    </v-tabs>

    <v-divider></v-divider>

    <v-tabs-items v-model="pos">
      <v-tab-item @click="setPosCotizador(0)">
        <Reservas
          :user="user"
          :states="states"
          :tipos_product="tipos_product"
          :names="names"
          :codes="codes"
          @loadAll="initAll()"
        />
      </v-tab-item>
      <v-tab-item @click="setPosCotizador(1)">
        <Cotizations
          :user="user"
          :states="states"
          :tipos_product="tipos_product"
          :names="names"
          :codes="codesCotizations"
          @loadAll="initAll()"
        />
      </v-tab-item>
    </v-tabs-items>
    <!--<v-card-text class="pt-2">
      <v-row>
        <v-col
          lg="12"
          cols="12"
          class="d-flex justify-end"
        >
          <v-pagination
            v-if="pos === 0"
            v-model="pagination.current"
            :length="pagination.total"
            total-visible="5"
            @input="onPageChange"
          ></v-pagination>
          <v-pagination
            v-if="pos === 1"
            v-model="paginationCotization.current"
            :length="paginationCotization.total"
            total-visible="5"
            @input="onPageChange"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card-text>-->
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiFormatListText,
  mdiAirplaneTakeoff,
  mdiCar,
  mdiBed,
  mdiClose,
  mdiEye,
  mdiEraserVariant,
} from '@mdi/js'

// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
import Reservas from '../tabs/Reservas.vue'
import FiltroReservas from '../tabs/FiltroReservas.vue'
import Cotizations from '../tabs/Cotizations.vue'
import FiltroCotizations from '../tabs/FiltroCotizations.vue'

export default {
  setup() {
    const { menuIsVerticalNavMini } = useAppConfig()

    return {
      menuIsVerticalNavMini,
    }
  },
  components: {
    Reservas,
    FiltroReservas,
    Cotizations,
    FiltroCotizations,
  },
  data() {
    return {
      currentTab: 0,
      isLoading: true,
      openFiltros: false,
      search: {
        proximas: false,
        activas: false,
        state: null,
      },
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
        wheelSpeed: 0.7,
      },
      reservas: [],
      pagination: {
        current: 1,
        total: 0,
      },
      totalItems: 0,
      totalF: 0,
      cotizations: [],
      paginationCotization: {
        current: 1,
        total: 0,
      },
      totalItemsCotization: 0,
      totalFCotization: 0,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiFormatListText,
        mdiAirplaneTakeoff,
        mdiCar,
        mdiBed,
        mdiClose,
        mdiEye,
        mdiEraserVariant,
      },
      isDialogVisible: false,
      isDialogVisibleDelete: false,
      item: {},
      itemId: null,
      abilities: [],
      abilityList: [],
      states: [],
      checkPermiso: 1,
      loading: false,
      permisos: sessionStorage.getItem('permisos_auth'),
      is_nivel_propietario: false,
      user: {},
      tipos_product: [],
      names: [],
      namesTraver: [],
      searchNameTraver: null,
      codes: [],
      codesCotizations: [],
      codesReserva: [],
      searchCodeReserva: null,
      afiliadosList: [],
      afiliados: [],
      afiliadosSearch: null,
      sellerList: [],
      seller: [],
      sellerSearch: null,
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      menuFromReserve: false,
      menuToReserve: false,
      menuFromService: false,
      menuToService: false,
      pos: 0,
    }
  },
  computed: {
    ...mapState({
      posCotizador: state => state.app.posCotizador,
      reservasAll: state => state.app.reservasAll,
      reservasPaginate: state => state.app.reservasPaginate,
      cotizationsAll: state => state.app.cotizationsAll,
      cotizationsPaginate: state => state.app.cotizationsPaginate,
      itemsPerPage: state => state.app.itemsPerPage,
      itemsPage: state => state.app.itemsPage,
      filtersReservas: state => state.app.filtersReservas,
      filtersCotizations: state => state.app.filtersCotizations,
    }),
    computedFromReserveDateFormatted() {
      return this.search.dateReserveFrom ? this.$moment(this.search.dateReserveFrom).format('D-MMM-YY') : ''
    },
    computedToReserveDateFormatted() {
      return this.search.dateReserveTo ? this.$moment(this.search.dateReserveTo).format('D-MMM-YY') : ''
    },
    computedFromServiceDateFormatted() {
      return this.search.dateServiceFrom ? this.$moment(this.search.dateServiceFrom).format('D-MMM-YY') : ''
    },
    computedToServiceDateFormatted() {
      return this.search.dateServiceTo ? this.$moment(this.search.dateServiceTo).format('D-MMM-YY') : ''
    },
  },
  watch: {
    searchNameTraver(val) {
      if (val.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterNameTraver(val.toLowerCase())
      } else {
        this.namesTraver = []
      }
    },
    searchCodeReserva(val) {
      if (val.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterCodeReserva(val.toLowerCase())
      } else {
        this.codesReserva = []
      }
    },

    afiliadosSearch(val) {
      if (val.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterAfiliados(val.toLowerCase())
      } else {
        this.afiliados = []
      }
    },
    sellerSearch(val) {
      if (val.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterSeller(val.toLowerCase())
      } else {
        this.seller = []
      }
    },
  },
  mounted() {
    // PARA Q NAZCA EL MENU IZQ OCULTO
    this.menuIsVerticalNavMini = true
    this.pos = this.posCotizador
    this.scrollReservas()
    this.scrollCotizations()
  },
  created() {
    this.profile()

    this.getStates()
    this.getTiposProduct()
    this.getNamesClient()
    this.getAfiliados()
  },
  methods: {
    ...mapMutations([
      'setPosCotizador',
      'setItemsCont',
      'setItemsPerPage',
      'updateReservasAll',
      'setReservasPaginate',
      'updateReservasPaginate',
      'setIdUserLevel0',
      'setIdUserAfiliates',
      'setItemsPage',
      'updateCotizationsAll',
      'setCotizationsPaginate',
      'updateCotizationsPaginate',
      'initFiltrosReservas',
    ]),
    scrollReservas() {
      window.onscroll = () => {
        const { scrollTop } = document.documentElement
        const scrollHeight = document.documentElement.offsetHeight
        const heightWin = window.innerHeight
        const scrollPosition = Math.ceil(heightWin + scrollTop)

        if (scrollPosition >= scrollHeight) {
          // PAGINAR SI LOS FILTROS ESTAN LIMPIO
          const filters1 = JSON.stringify({
            dateReserveFrom: null,
            dateReserveTo: null,
            dateServiceFrom: null,
            dateServiceTo: null,
            tipo_product: null,
            fullName: null,
            afiliate: null,
            seller: null,
            codeReserva: null,
            state: null,
          })
          const filters2 = JSON.stringify(this.filtersReservas)

          if (filters1 === filters2) {
            const reserv = []
            // eslint-disable-next-line no-plusplus
            for (let index = (this.itemsPage * this.itemsPerPage); index < this.reservasAll.length; index++) {
              const element = this.reservasAll[index]

              if (index < ((this.itemsPage + 1) * this.itemsPerPage)) {
                reserv.push(element)
              }
            }
            this.setItemsPage(this.itemsPage + 1)
            this.updateReservasPaginate(reserv)
          }
        }
      }
    },
    scrollCotizations() {
      window.onscroll = () => {
        const { scrollTop } = document.documentElement
        const scrollHeight = document.documentElement.offsetHeight
        const heightWin = window.innerHeight
        const scrollPosition = Math.ceil(heightWin + scrollTop)
        if (scrollPosition >= scrollHeight) {
          // PAGINAR SI LOS FILTROS ESTAN LIMPIO
          const filters1 = JSON.stringify({
            dateReserveFrom: null,
            dateReserveTo: null,
            dateServiceFrom: null,
            dateServiceTo: null,
            tipo_product: null,
            fullName: null,
            afiliate: null,
            seller: null,
            codeReserva: null,
            state: null,
          })
          const filters2 = JSON.stringify(this.filtersCotizations)

          if (filters1 === filters2) {
            const reserv = []
            // eslint-disable-next-line no-plusplus
            for (let index = (this.itemsPage * this.itemsPerPage); index < this.cotizationsAll.length; index++) {
              const element = this.cotizationsAll[index]

              if (index < ((this.itemsPage + 1) * this.itemsPerPage)) {
                reserv.push(element)
              }
            }
            this.setItemsPage(this.itemsPage + 1)
            this.updateCotizationsPaginate(reserv)
          }
        }
      }
    },
    filterNameTraver(v) {
      this.namesTraver = []
      if (v === '') {
        this.namesTraver = []
      } else {
        this.namesTraver = this.names.filter(e => e.name.toLowerCase())
      }
    },
    filterCodeReserva(v) {
      this.codesReserva = []
      if (v === '') {
        this.codesReserva = []
      } else {
        this.codesReserva = this.codes.filter(e => e.toLowerCase())
      }
    },
    filterAfiliados(v) {
      this.afiliados = []
      if (v === '') {
        this.afiliados = []
      } else {
        this.afiliados = this.afiliadosList.filter(e => e.name.toLowerCase())
      }
    },
    filterSeller(v) {
      this.seller = []
      if (v === '') {
        this.seller = []
      } else {
        this.seller = this.sellerList.filter(e => e.name.toLowerCase())
      }
    },
    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          } else if (response.data.data.nivel === -1) {
            this.is_nivel_propietario = true
          }
          this.user = response.data.data
        })
        // eslint-disable-next-line no-return-assign
    },
    getStates() {
      this.axios
        .get('state_reservations?per_page=10000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.states = res.data.data
        })
        .finally(() => {
          this.initAll()
        })
    },
    getTiposProduct() {
      this.axios
        .get('type_product?per_page=10000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          const result = res.data.data
          result.forEach(element => {
            if (
              element.entity !== 'transfers'
              && element.entity !== 'excursions'
              && element.entity !== 'packages'
              && element.entity !== 'cruise_ships'
            ) {
              this.tipos_product.push(element)
            }
          })
        })
    },
    getNamesClient() {
      this.axios
        .post(
          'reservations/names-client',
          { type: 'reservas' },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          if (res.data.success) {
            this.names = res.data.data.names
          }
        })
    },
    getCodesReservas() {
      this.axios
        .post(
          'reservations/codes-reservas',
          { type: 'reservas' },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          if (res.data.success) {
            this.codes = res.data.data.codes
          }
        })
        .finally(() => {
          this.getCodesCotizations()
        })
    },
    getAfiliados() {
      this.axios
        .post(
          'reservations/afiliados-reservas',
          { type: 'reservas' },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          if (res.data.success) {
            this.afiliadosList = res.data.data.afiliados
          }
        })
        .finally(() => {
          this.getSeller()
        })
    },
    getSeller() {
      this.axios
        .post(
          'reservations/seller-reservas',
          { type: 'reservas' },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          if (res.data.success) {
            this.sellerList = res.data.data.seller
          }
        })
        .finally(() => {
          this.getCodesReservas()
        })
    },
    getReservas() {
      this.openFiltros = false
      const json = {
        page: this.pagination.current,
        per_page: this.itemsPerPage,
        type: 'reservas',
        search: this.search,
      }
      this.axios
        .post('reservations/list', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            // this.reservas = res.data.data
            const reservasList = res.data.data
            reservasList.sort((a, b) => new Date(a.date_order) - new Date(b.date_order))
            this.setItemsCont(res.data.total)
            this.updateReservasAll(reservasList)
            this.setIdUserLevel0(res.data.idUserLevel0)
            this.setIdUserAfiliates(res.data.idUserAfiliates)
            const reserv = []
            // eslint-disable-next-line no-plusplus
            for (let index = 0; index < reservasList.length; index++) {
              const element = reservasList[index]

              if (index < this.itemsPerPage) {
                reserv.push(element)
              }
            }
            this.setReservasPaginate(reserv)

            const ids = []
            this.states.forEach(element => {
              if (element.slug === 'en-proceso' || element.slug === 'confirmada') {
                ids.push(element.id)
              }
            })
            this.initFiltrosReservas(ids)

            // this.pagination.current = res.data.meta.current_page
            // this.pagination.total = res.data.meta.last_page
            // this.totalF = res.data.meta.total
          }
        })
    },
    getCotizations() {
      this.openFiltros = false
      const json = {
        page: this.paginationCotization.current,
        per_page: this.itemsPerPage,
        type: 'cotization',
        search: this.search,
      }
      this.axios
        .post('reservations/list', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            const cotizationsList = res.data.data
            cotizationsList.sort((a, b) => new Date(a.date_order) - new Date(b.date_order))
            this.setItemsCont(res.data.total)
            this.updateCotizationsAll(cotizationsList)

            // this.setIdUserLevel0(res.data.idUserLevel0)
            // this.setIdUserAfiliates(res.data.idUserAfiliates)

            const reserv = []
            // eslint-disable-next-line no-plusplus
            for (let index = 0; index < res.data.data.length; index++) {
              const element = res.data.data[index]

              if (index < this.itemsPerPage) {
                reserv.push(element)
              }
            }
            this.setCotizationsPaginate(reserv)
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    getCodesCotizations() {
      this.axios
        .post(
          'reservations/codes-reservas',
          { type: 'cotization' },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          if (res.data.success) {
            this.codesCotizations = res.data.data.codes
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    changeCantItems() {
      if (this.pos === 0) {
        this.getReservas()
      } else {
        this.getCotizations()
      }
    },
    searchAll() {
      this.pagination.current = 1
      if (this.pos === 0) {
        this.getReservas()
      } else {
        this.getCotizations()
      }
    },
    getReservasAll() {
      /* const json = {
        page: this.pagination.current,
        type: 'reservas',
        per_page: 1000,
        search: this.search,
      }
      this.axios
        .post('reservations/list', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.totalItems = res.data.meta.total
          }
        }) */
    },
    getCotizationsAll() {
      /* const json = {
        page: this.paginationCotization.current,
        type: 'cotization',
        per_page: 1000,
        search: this.search,
      }
      this.axios
        .post('reservations/list', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.totalItemsCotization = res.data.meta.total
          }
        }) */
    },
    onPageChange() {
      if (this.pos === 0) {
        this.getReservas()
      } else {
        this.getCotizations()
      }
    },
    reseat() {
      this.isDialogVisible = false
      this.isDialogVisibleDelete = false
      this.item = {}
      this.itemId = null
      this.loading = false
      this.loadAll()
    },
    loadAll() {
      this.getReservas()
      this.getCotizations()
    },
    initAll() {
      this.getReservas()
      this.getCotizations()

      // this.getReservasAll()
      // this.getCotizationsAll()
    },

    clearSearch() {
      this.search = {}
      this.loadAll()
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
